body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
a {
  outline: none;
  text-decoration: none;
}
a:focus,
a:hover {
  outline: none;
  text-decoration: none;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.ant-checkbox-inner {
  border: 2px solid #edf2f9;
}

.primarybtn {
  display: inline-block;
  border: none;
  box-shadow: none;
  outline: none;
  font-size: 14px;
  line-height: 1;
  color: #fff;
  font-weight: 500;
  margin: 0;
  padding: 0 15px;
  min-width: 150px;
  height: 42px;
  background: #3e79f7;
  border-radius: 0.625rem;
}
.primarybtn:hover,
.primarybtn:focus {
  color: #fff !important;
  background: #699dff !important;
}
p {
  font-size: 14px;
  line-height: 1.8;
  font-weight: 400;
  color: #72849a;
  margin: 0;
  padding: 0;
}

/*=============== Home Page Start ===============*/
.app-header {
  position: fixed;
  top: 0;
  height: 50px !important;
  left: 0;
  right: 0;
  background: #fff;
  box-shadow: 0 1px 4px -1px rgb(0 0 0 / 15%);
  z-index: 1000;
  padding: 0;
  line-height: 1;
}

.side-nav {
  height: calc(100vh - 50px);
  position: fixed;
  top: 50px;
  box-shadow: rgb(0 0 0 / 15%) 0px 1px 4px -1px;
  z-index: 999;
  background-color: rgb(255, 255, 255);
  min-width: 200px !important;
  transition: all 0.2s ease-in-out;
}

.body-element.active .side-nav {
  min-width: 70px !important;
  width: 70px !important;
}

.body-element .app-layout {
  padding-left: 200px;
  transition: all 0.2s ease-in-out;
}
.body-element.active .app-layout {
  padding-left: 70px;
}

.body-element .app-content {
  padding: 0;
  margin-top: 50px;
  min-height: calc(100vh - 50px);
  position: relative;
}

.body-element .app-content .ant-layout-content {
  height: 100%;
}

/*=============== Home Page End ===============*/
.ant-card-body .ant-input-affix-wrapper {
  height: 42px;
  padding-top: 0;
  padding-bottom: 0;
}

.login-form-password-label {
  display: grid;
  grid-template-columns: 29fr 9fr;
}

/*=============== Header Start ===============*/
.header-block {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.header-block .logo-block {
  width: 200px;
  margin: 0;
  padding: 0 15px;
  transition: all 0.2s ease-in-out;
}
.header-block .logo-block img {
  width: 150px;
  height: auto;
}
.header-block .logo-block img.sm-logo {
  width: 30px;
  height: auto;
}

.header-block .logo-block.collapsed {
  width: 70px;
  overflow: hidden;
  text-align: center;
}

.header-block .navbar {
  flex: 1;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-block .nav-left {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.profile-dropdown {
  position: fixed;
  top: 55px !important;
}

.hamburger-menu {
  margin: 0;
  padding: 0 15px;
  display: flex;
  align-items: center;
}
.hamburger-menu .ant-btn {
  box-shadow: none;
  outline: none;
  border: none;
  background: none;
  height: auto;
  margin: 0;
  padding: 5px;
  font-size: 20px;
}
.hamburger-menu .ant-btn:after {
  display: none !important;
}
.hamburger-menu .ant-btn:focus {
  box-shadow: none;
  outline: none;
  border: none;
}
/* .hamburger-menu .ant-btn .a-icon{ display:none; opacity:0; } */

.hamburger-menu .ant-btn:hover {
  color: #3e79f7 !important;
  background: none !important;
}
.hamburger-menu .ant-btn:focus {
  color: #203251;
  outline: none;
  box-shadow: none;
  background: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.hamburger-menu .ant-breadcrumb {
  margin-left: 20px;
}
.hamburger-menu .ant-breadcrumb span {
  font-size: 25px;
  line-height: 1;
  font-weight: 600;
  color: #203251;
}
.hamburger-menu .ant-breadcrumb span a {
  color: #203251;
}

.header-block .nav-right {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  height: 45px;
}
.header-block .nav-right .ant-badge a {
  color: #455560;
}
.header-block .nav-right .ant-badge .anticon {
  font-size: 20px;
}

.header-block .nav-right .avatar-box {
  padding: 0 15px;
}
.header-block .nav-right .avatar-btn {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: #ccc;
}
.header-block .nav-right .avatar-btn img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.navigation-menu {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}
.navigation-menu .ant-menu {
  background: none !important;
}
.navigation-menu .ant-menu-item {
  font-size: 14px;
  line-height: 40px;
  font-weight: 500;
  color: #455560 !important;
  position: relative;
  height: 40px;
}
.navigation-menu .ant-menu-item:after {
  content: "";
  position: absolute;
  width: 2px;
  right: 0;
  top: 0;
  bottom: 0;
  background: transparent;
}
.navigation-menu .ant-menu-item.ant-menu-item-selected:after {
  background: #3e79f7;
}
.navigation-menu .ant-menu-item .anticon {
  font-size: 17px;
  margin-right: 10px;
  min-width: 14px;
}
.navigation-menu .ant-menu-item span a {
  color: #455560 !important;
}
.navigation-menu .ant-menu-item:hover {
  color: #3e79f7 !important;
}
.navigation-menu .ant-menu-item:hover span a {
  color: #3e79f7 !important;
}
.navigation-menu .ant-menu-item:hover .anticon {
  color: #3e79f7 !important;
}
.navigation-menu .ant-menu-item.ant-menu-item-selected {
  color: #3e79f7 !important;
  background: #ebf1fe !important;
}
.navigation-menu .ant-menu-item.ant-menu-item-selected span a {
  color: #3e79f7 !important;
}
.navigation-menu .ant-menu-item.ant-menu-item-selected .anticon {
  color: #3e79f7 !important;
}

.navigation-menu .ant-menu-submenu {
  font-size: 14px;
  line-height: 40px;
  font-weight: 500;
  color: #455560 !important;
  position: relative;
}

.navigation-menu .ant-menu-submenu .ant-menu-submenu-title {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigation-menu
  .ant-menu-submenu.ant-menu-submenu-selected
  .ant-menu-submenu-title {
  color: #3e79f7 !important;
  background: #ebf1fe !important;
  border-right: 2px solid #3e79f7;
}

.navigation-menu .ant-menu-submenu .ant-menu-item.ant-menu-item-selected {
  background: none !important;
}
.navigation-menu .ant-menu-submenu .ant-menu-item.ant-menu-item-selected:after {
  display: none;
}
.navigation-menu .ant-menu-submenu .ant-menu-item.ant-menu-item-selected a {
  color: #3e79f7 !important;
}

.navigation-menu .ant-menu-submenu-active .ant-menu-submenu-title:hover {
  color: #455560 !important;
}
.navigation-menu .ant-menu-submenu .ant-menu-item {
  font-size: 14px;
  line-height: 40px;
  font-weight: 400;
  color: #455560 !important;
}
.navigation-menu .ant-menu-submenu .ant-menu-item a {
  color: #455560 !important;
}

.navigation-menu .ant-menu-submenu .ant-menu-submenu-arrow:before,
.navigation-menu .ant-menu-submenu .ant-menu-submenu-arrow:after {
  background: #455560 !important;
}

.navigation-menu .collaped-menu-active {
  display: none;
}

.ant-menu-sub .ant-menu-item.ant-menu-item-selected {
  background: none !important;
}

.body-element.active .navigation-menu {
  overflow: inherit;
}
.body-element.active .navigation-menu .ant-menu-item {
  font-size: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.body-element.active .navigation-menu .ant-menu-item {
  padding-left: 15px !important;
  text-align: center;
}
.body-element.active .navigation-menu .ant-menu-item .anticon {
  margin-right: 0;
}

.body-element.active .navigation-menu .ant-menu-submenu-title {
  padding-left: 10px !important;
  padding-right: 18px !important;
  text-overflow: inherit;
  text-align: center;
}
.body-element.active .navigation-menu .ant-menu-submenu-title span {
  display: none;
}
.body-element.active .navigation-menu .ant-menu-submenu-title .anticon-cluster {
  display: inline-block;
  margin-right: 0;
}
.body-element.active
  .navigation-menu
  .ant-menu-submenu-title
  .anticon-dashboard {
  display: inline-block;
  margin-right: 0;
}
.body-element.active
  .navigation-menu
  .ant-menu-submenu-title
  .anticon-fund-view {
  display: inline-block;
  margin-right: 0;
}
.body-element.active
  .navigation-menu
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow {
  display: none;
}

.profile-header .hamburger-menu .menu-btn {
  display: none;
}
.nav-profile-header .d-flex {
  align-items: center;
}

.body-element.active .navigation-menu .collaped-menu {
  display: none;
}
.body-element.active .navigation-menu .collaped-menu-active {
  display: block;
}
.body-element.active
  .navigation-menu
  .collaped-menu-active
  .ant-menu-item-selected {
  border-right: 2px solid #3e79f7;
}

/*=============== Header End ===============*/

/*=============== Home Page Start ===============*/
.csb-home {
  margin: 50px 0 0 0;
  padding: 30px 15px;
}
.header-block.csb-header {
  padding: 0 15px;
}
.header-block.csb-header .logo-block {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}
.header-block.csb-header .logo-block .logo-icon {
  margin: 0 12px 0 0;
  padding: 0;
}

.csb-header .search-field {
  margin: 0 0 0 25px;
  padding: 0;
  width: 300px;
}

.csb-header .nav-right {
  margin: 0 0 0 auto;
}

.nav-profile-body .ant-menu-item {
  overflow: inherit;
}

/*=============== Home Page End ===============*/

/*=============== Dashboard Start ===============*/
.element-info {
  margin: 0;
  padding: 20px;
  position: relative;
}
.element-info .datepicker-field {
  position: absolute;
  left: 28px;
  top: 20px;
  z-index: 1;
}
.datepicker-field .ant-picker,
.datepicker-field .ant-picker-input > input {
  border-radius: 10px;
  height: 42px;
}
.datepicker-field .ant-picker-active-bar {
  display: none;
}
.datepicker-field .ant-picker:hover,
.datepicker-field .ant-picker-focused {
  border: 1px solid #3579f6;
}

.element-info .ant-tabs-nav::before {
  display: none !important;
}
.element-info .ant-tabs-nav-wrap {
  justify-content: center;
}
.element-info .ant-tabs-nav-list {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
}
.element-info .ant-tabs-nav-list .ant-tabs-tab {
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  color: #455560;
  margin: 0;
  padding: 13px 15px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.element-info .ant-tabs-nav-list .ant-tabs-tab:first-child {
  border-left: none;
}
.element-info .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
  background: #3579f6;
}
.element-info
  .ant-tabs-nav-list
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #fff;
}

.element-info .ant-tabs-nav-wrap .ant-tabs-ink-bar {
  display: none;
}

.chart-element {
  margin: 0;
  padding: 0 0 30px 0;
}

.chart-element .card {
  margin: 0;
  padding: 0;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.no-border {
  border: none;
}
.chart-element .chart-row .ant-row {
  margin: 0 !important;
}

.chart-element .item {
  width: 20%;
  margin: 0 0 20px 0;
  padding: 0 10px !important;
  box-sizing: border-box;
}
.chart-element .item1 {
  width: 25%;
  margin: 0 0 20px 0;
  padding: 0 10px !important;
  box-sizing: border-box;
}
.chart-element .top-chart {
  display: flex;
  flex-flow: wrap;
}
.chart-element .top-chart .box {
  margin: 0;
  padding: 25px 15px;
  display: flex;
  align-items: center;
  flex-flow: column;
  position: relative;
  overflow: hidden;
  min-height: 150px;
}
.chart-element .top-chart .box .text-field {
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  position: relative;
  z-index: 1;
}
.chart-element .top-chart .box .text-field h3 {
  font-size: 11px;
  line-height: 1.3;
  font-weight: 500;
  color: #aeb3bd;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
  padding: 0 0 10px 0;
}
.chart-element .top-chart .box .text-field h2 {
  font-size: 30px;
  line-height: 1;
  font-weight: 700;
  color: #41506d;
  margin: 0;
  padding: 0 0 10px 0;
}
.chart-element .top-chart .box .text-field span {
  display: block;
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  color: #b42e40;
  margin: 0;
  padding: 0;
}

.chart-element .top-chart .box .line-chart {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.chart-element .top-chart.aga-tp-chart .box {
  justify-content: center;
}

.chart-element .bottom-chart {
  margin: 0;
  padding: 0;
}
.chart-element .bottom-chart .box {
  display: flex;
  flex-flow: column;
}

.chart-element .chart-row .header-title {
  margin: 0;
  padding: 15px;
  position: relative;
  border-bottom: 1px solid #e1e5eb;
}
.chart-element .chart-row .header-title h3 {
  font-size: 15px;
  line-height: 1.3;
  font-weight: 500;
  color: #3d5170;
  margin: 0;
  padding: 0 25px 0 0;
}
.chart-element .chart-row .header-title h3 .anticon {
  margin-left: 5px;
}

.chart-element .chart-row .header-title .handle-line {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  opacity: 1;
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 0;
  padding: 5px;
  background: none !important;
}
.chart-element .chart-row .header-title .handle-line:after {
  display: none;
}
.chart-element .chart-row .header-title .handle-line:hover {
  color: #3e82f7;
}
.chart-element .chart-row .header-title .handle-line img {
  width: 12px;
  height: auto;
}

.chart-element .chart-row .card-body {
  flex: 1;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: column;
}
.chart-element .chart-row .card-body.scroll-info {
  max-height: 270px;
  overflow: auto;
}

.chart-element .card-body .up-field {
  margin: 0;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chart-element .card-body .up-field .btn-group {
  margin: 0;
  padding: 0;
  border: 1px solid #e1e5eb;
  border-radius: 3px;
}
.chart-element .card-body .up-field .btn-group .ant-btn {
  font-size: 12px;
  line-height: 1;
  font-weight: 400;
  color: #3d5170;
  margin: 0;
  padding: 7px 15px;
  border: none;
  border-right: 1px solid #e1e5eb;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  height: auto;
}
.chart-element .card-body .up-field .btn-group .ant-btn:hover {
  box-shadow: 0 0.125rem 0.625rem rgb(129 142 163 / 20%),
    0 0.0625rem 0.125rem rgb(129 142 163 / 30%);
}
.chart-element .card-body .up-field .btn-group .ant-btn:first-child {
  border-radius: 3px 0 0 3px;
}
.chart-element .card-body .up-field .btn-group .ant-btn:last-child {
  border-right: none;
  border-radius: 0 3px 3px 0;
}
.chart-element .card-body .up-field .btn-group .ant-btn.active {
  background: #017bfd;
  color: #fff;
}
.chart-element .card-body .chart-block {
  flex: 1;
  margin: 0;
  padding: 0;
}
.chart-element .card-body .chart-block canvas {
  width: 100% !important;
}

.chart-element .card-body .date-field {
  margin: 0;
  padding: 0;
  display: flex;
}
.chart-element .card-body .date-field .box {
  margin: 0;
  padding: 0;
  border: 1px solid #e1e5eb;
}
.chart-element .card-body .date-field .react-datepicker__input-container {
  width: 100px;
}
.chart-element
  .card-body
  .date-field
  .react-datepicker__input-container
  input[type="text"] {
  width: 100%;
  border: none;
  font-size: 12px;
  height: 26px;
  text-align: center;
  outline: none;
}
.chart-element .card-body .date-field .box:nth-child(2) {
  border-right: none;
  border-left: none;
}
.chart-element .card-body .date-field .box:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}
.chart-element .card-body .date-field .box .anticon {
  color: #aab5be;
}

.chart-element .card-body ul {
  margin: 0;
  padding: 0;
}
.chart-element .card-body ul li {
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  color: #3d5170;
  margin: 0;
  padding: 15px;
  border-bottom: 1px solid #e1e5eb;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chart-element .card-body ul li:last-child {
  border-bottom: none;
}

.chart-element .card-body .value-text {
  color: #818ea3;
}

.chart-element .card-footer {
  margin: 0;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e1e5eb;
}

.chart-element .card-footer .select-box {
  margin: 0;
  padding: 0;
}
.chart-element .card-footer .select-box .ant-select-selector {
  font-size: 12px;
  color: #495057;
  border-radius: 3px;
}
.chart-element .card-footer .select-box .ant-select:hover .ant-select-selector {
  border: 1px solid #b3bdcc;
}

.chart-element .card-footer .view-report {
  margin: 0;
  padding: 0;
}
.chart-element .card-footer .view-report a {
  display: inline-block;
  font-size: 13px;
  line-height: 1;
  font-weight: 600;
  color: #3d5170;
  margin: 0;
  padding: 0;
}
.chart-element .card-footer .view-report a:hover {
  color: #007bff;
}

.progress-view {
  margin: 0;
  padding: 0;
}
.chart-element .chart-row .progress-view .ant-row {
  margin: 0 -8px !important;
}
.chart-element .card-body .progress-view ul li {
  display: block;
  padding: 20px 15px;
}

.campaign-info .btn-block {
  padding-right: 8px;
}

.progress-view .lt-text {
  margin: 0;
  padding: 0;
}
.progress-view .lt-text h4 {
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  color: #3d5170;
  margin: 0;
  padding: 0 0 10px 0;
}
.progress-view p {
  font-size: 12px;
  line-height: 1;
  font-weight: 400;
  color: #3d5170;
  margin: 0;
  padding: 0;
}
.progress-view .lt-text p span {
  color: #33b85e;
}

.progress-view .rt-text {
  margin: 0;
  padding: 0;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.progress-view .rt-text .text {
  margin: 0;
  padding: 0;
}
.progress-view .rt-text .progress-text {
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  color: #3d5170;
  margin: 0;
  padding: 0 0 10px 0;
}

.progress-view .rt-text .progress-bar {
  margin: 0 0 0 10px;
  padding: 0;
}
.progress-view .rt-text .progress-bar .ant-progress-inner {
  width: 35px !important;
  height: 35px !important;
}
.progress-view .rt-text .progress-bar .ant-progress-text {
  display: none;
}

.pie-chart {
  padding: 20px;
}
.piechart-field {
  padding: 20px;
}

.country-list .label-text {
  display: flex;
  align-items: center;
}
.country-list .label-text .color-dotted {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 8px;
}
.country-list .value-text {
  font-weight: 700;
  color: #1a3353 !important;
}

.chart-element .bottom-chart .box .ant-table-cell .btn {
  border: none;
  box-shadow: none;
  outline: none;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  color: #fff;
  background: #4d78ef;
  margin: 0;
  padding: 14px 18px;
  height: auto;
  border-radius: 10px;
}
.chart-element .bottom-chart .box .ant-table-cell .btn:hover {
  background: #3579f6;
}

.chart-element .chart-row .card-body .apexcharts-menu-icon {
  display: none;
}

/*=============== Dashboard End ===============*/

/*=============== Activity Section Start ===============*/
.ac-drawer .ant-drawer-content-wrapper {
  width: 400px !important;
}

.timeline-info .ant-timeline-item-tail {
  border-left-style: dashed;
}

.timeline-info .ant-breadcrumb a,
.timeline-info .ant-typography p,
.timeline-info p,
.timeline-info p.ant-typography {
  color: #72849a;
  margin-bottom: 1em;
}

/*=============== Activity Section End ===============*/

/*=============== Campaign Page Start ===============*/
.campaign-info .chart-element .item {
  width: 16.66%;
}
.campaign-info .top-field {
  margin: 0 0 15px 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.campaign-info .top-field .datepicker-field {
  position: relative;
  top: 0;
  left: 8px;
}

.cmpg-report-table .ant-table-tbody .ant-table-cell .dotted {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #9e9e9e;
  margin: 0 7px 0 0;
  padding: 0;
}

.cmpg-report-table .ant-table-tbody .name-text p span {
  display: block;
  font-weight: 600;
  color: #5e99e2;
}
.cmpg-report-table .ant-table-tbody .ant-table-cell .btn {
  border: none;
  background: none;
  height: auto;
  box-shadow: none;
  outline: none;
  padding: 5px;
  font-size: 16px;
}
.cmpg-report-table .ant-table-tbody .ant-table-cell .btn:hover {
  color: #5e99e2;
}

.base-form {
  margin: 0;
  padding: 0;
}
.base-form .ant-upload a {
  color: #7c899d;
  font-weight: 500;
}

.base-drawer .ant-drawer-footer {
  padding-left: 24px;
  padding-right: 40px;
}

.dwn-text label {
  display: block;
  margin: 0;
  padding: 0;
  text-align: right;
  width: 100%;
}

.base-form .list-field li h4 {
  padding-bottom: 9px;
}

/*=============== Campaign Page End ===============*/

/*=============== Settings Page Start ===============*/
.settings-info {
  max-width: 925px;
}
.settings-info .ant-row .ant-col-4 {
  flex: 20%;
  max-width: 20%;
}
.settings-info .box {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}
.settings-info .box:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
.settings-info .ant-card-body {
  padding: 0;
}
.settings-info .box a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  margin: 0;
  padding: 15px;
  text-align: center;
  min-height: 150px;
}

.settings-info .box .icon-img {
  margin: 0 0 15px 0;
  padding: 0;
}
.settings-info .box .icon-img img {
  width: auto;
  height: auto;
  max-height: 50px;
}

.settings-info .box .text {
  margin: 0;
  padding: 0;
}
.settings-info .box .text h4 {
  font-size: 14px;
  line-height: 1.5;
  color: #2c3951;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.stgn-element {
  margin: 0;
  padding: 20px;
  height: 100%;
}
.info-wrap {
  height: 100%;
  margin: 0;
  padding: 0;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.tab-field-element {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
}
.tab-field-element .lt-block {
  width: 235px;
  margin: 0;
  padding: 0;
  border-right: 1px solid #e7ebf0;
  display: flex;
  flex-flow: column;
}

.tab-field-element .top-btn-block,
.tab-field-element .bottom-btn-block {
  margin: 0;
  padding: 15px;
}
.tab-field-element .top-btn-block .add-user-btn {
  width: 100%;
}

.tab-field-element .bottom-btn-block .btn-field {
  margin-bottom: 0;
}
.tab-field-element
  .bottom-btn-block
  .btn-field
  .ant-form-item-control-input-content {
  display: block;
}

.tab-field-element .ant-tabs-nav-list {
  flex: 1;
  margin: 0;
  padding: 0;
}
.tab-field-element .ant-tabs-nav-list ul li {
  font-size: 14px;
  line-height: 40px;
  font-weight: 500;
  color: #455560;
  position: relative;
  min-height: 40px;
  margin: 4px 0 8px;
  padding: 0 15px;
  cursor: pointer;
}
.tab-field-element .ant-tabs-nav-list ul li .anticon {
  font-size: 17px;
  margin-right: 10px;
  min-width: 14px;
}
.tab-field-element .ant-tabs-nav-list ul li:after {
  content: "";
  position: absolute;
  width: 3px;
  top: 0;
  bottom: 0;
  right: 0;
  background: #3e79f7;
  visibility: hidden;
  opacity: 0;
}
.tab-field-element .ant-tabs-nav-list ul li:hover {
  color: #3e79f7;
}
.tab-field-element .ant-tabs-nav-list ul li.active {
  background: #ebf1fe;
  color: #3e79f7;
}
.tab-field-element .ant-tabs-nav-list ul li.active:after {
  visibility: visible;
  opacity: 1;
}

.tab-field-element .bottom-btn-block .ant-form-item-control-input-content {
  display: flex;
  align-items: center;
}

.tab-field-element .rt-block {
  flex: 1;
  margin: 0;
  padding: 0;
}

.tabcontent-wrap {
  margin: 0;
  padding: 20px;
  position: relative;
}
.content-wrap {
  margin: 0;
  padding: 8px;
}
.content-wrap .top-filter {
  margin: 0;
  padding: 0 0 15px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.content-wrap .top-info-row {
  margin: 0;
  padding: 0 0 15px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 12px;
  color: grey;
}
.content-wrap .top-info-row .info-text-lt {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.content-wrap .top-info-row .info-text-rt {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.content-wrap .top-filter-contacts {
  margin: 0;
  padding: 0 0 15px 0;
  display: grid;
  grid-template-columns: 22fr 1fr;
}

.content-wrap .top-filter .search-box {
  width: 420px;
}
.content-wrap .table-element {
  max-width: 1100px;
}

.content-wrap .top-filter .filter-btn {
  margin: 0;
  padding: 0;
}
.content-wrap .top-filter .filter-btn .ant-btn {
  width: 35px;
  height: 35px;
  border: none;
  background: none;
  margin: 0 0 0 22px;
  padding: 0;
  font-size: 20px;
  background: #ededed;
}

.profile-layout {
  margin-top: 50px;
}

.prf-back-btn .back-btn {
  width: 100%;
}

.tab-field-element .ant-tabs-nav-list.list-nav-tab ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tab-field-element .ant-tabs-nav-list.list-nav-tab ul li {
  line-height: 1.5;
}
.tab-field-element .ant-tabs-nav-list ul li .text {
  flex: 1;
}

.tab-field-element .lt-block.prf-list {
  display: block;
}

/*=============== Settings Page End ===============*/

/*=============== App Store Page Start ===============*/
.store-info .search-filter {
  max-width: 500px;
  margin: 0 0 20px 0;
  padding: 0;
}

.search-filter .ant-input-group {
  display: flex;
  flex-flow: row-reverse;
}
.search-filter .ant-input-group-addon {
  width: 40px;
  border-radius: 10px;
  left: 0 !important;
}
.search-filter .ant-input-group-addon .ant-input-search-button {
  border-radius: 10px 0 0 10px !important;
  border-right: none;
}
.search-filter .ant-input.ant-input-search:hover + .ant-input-group-addon {
  border-left-color: transparent !important;
}
.search-filter .ant-input-group .ant-input {
  border-radius: 0 10px 10px 0 !important;
}

.search-filter .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: #edf2f9 !important;
}

.store-info .box {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}
.store-info .box:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.store-info .box .logo-img {
  margin: 0 0 20px 0;
  padding: 0;
  text-align: center;
}
.store-info .box .logo-img img {
  width: auto;
  height: auto;
  max-height: 70px;
}

.store-info .box .text-block {
  margin: 0;
  padding: 0;
}
.store-info .box .text-block h2 {
  font-size: 18px;
  line-height: 1.3;
  font-weight: 500;
  color: #3d5170;
  margin: 0;
  padding: 0 0 4px 0;
}
.store-info .box .text-block span {
  display: block;
  font-size: 12px;
  line-height: 1;
  font-weight: 400;
  color: #828282;
  margin: 0;
  padding: 0 0 15px 0;
}
.store-info .box .text-block p {
  margin: 0;
  padding: 0 0 15px 0;
}

.store-info .box .text-block a {
  display: block;
  font-size: 16px;
  line-height: 1;
  color: #3e79f7;
  font-weight: 500;
  text-align: center;
}
.store-info .box .text-block a:hover {
  color: #3d5170;
}

/*=============== App Store Page End ===============*/

/*=============== Channels Start ===============*/
.channel-tab-list .ant-menu {
  background: none !important;
}
.channel-tab-list .ant-menu .ant-menu-submenu {
  padding-left: 0;
  padding-right: 0;
}
.channel-tab-list .ant-menu-submenu-arrow {
  opacity: 1 !important;
}
.channel-tab-list .ant-menu-submenu-arrow:before,
.channel-tab-list .ant-menu-submenu-arrow:after {
  background: #455560 !important;
  opacity: 1;
}
.channel-tab-list .ant-menu .ant-menu-submenu-title:hover {
  color: #3e79f7;
}
.channel-tab-list .ant-menu .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #3e79f7 !important;
}
.channel-tab-list .ant-menu .ant-menu-item {
  position: relative;
}
.channel-tab-list .ant-menu .ant-menu-item:after {
  content: "";
  position: absolute;
  width: 3px;
  top: 0;
  bottom: 0;
  right: 0;
  background: #3e79f7;
  visibility: hidden;
  opacity: 0;
}
.channel-tab-list .ant-menu .ant-menu-item.ant-menu-item-selected:after {
  visibility: visible;
  opacity: 1;
}
.channel-tab-list .ant-menu .ant-menu-item.ant-menu-item-selected {
  background: #ebf1fe !important;
  color: #3e79f7;
}

.chn-data h2 {
  margin: 0 0 8px 0;
  padding: 0;
}
.chn-data p {
  margin: 0 0 14px 0;
  padding: 0;
}
.chn-data .mt-4 h4 {
  font-size: 17px;
  line-height: 1.4;
  color: #1a3353;
  margin: 0 0 8px 0;
  padding: 0;
}

.chn-data .code {
  margin: 0;
  padding: 0;
  position: relative;
}
.chn-data .code .btn-box {
  position: absolute;
  top: 15px;
  right: 15px;
}
.chn-data .code .btn-box .copy-btn {
  background: none;
  color: #fff;
  opacity: 0.5;
}
.chn-data .code .btn-box .copy-btn:hover {
  background: none;
  color: #fff;
  opacity: 0.7;
}
.chn-data .code .btn-box .copy-btn:focus {
  background: none;
  color: #fff;
  opacity: 0.7;
}

.cst-cong {
  margin: 0;
  padding: 0;
}
.cst-cong .switch-block {
  margin: 0;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6ebf1;
}
.cst-cong .switch-block .label-text {
  display: inline-block;
  font-size: 20px;
  line-height: 1;
  font-weight: 700;
  color: #1a3353;
  margin: 0 15px 0 0;
  padding: 0;
}
.cst-cong .switch-block .ant-switch-checked {
  background-color: #04d182;
  outline: none;
  border: none;
}

.cst-cong .bottom-field {
  margin: 0;
  padding: 30px;
}
.cst-cong .bottom-field .ant-form-item-label {
  margin-right: 20px;
}
.cst-cong .bottom-field .ant-form-item-label label:after {
  display: none;
}

.whta-info .btn-sec {
  flex: 1;
  display: flex;
}

.csat-btn {
  margin-left: 20px;
}

/*=============== Channels End ===============*/

/*=============== Message Start ===============*/
.tab-field-element.chnl-tab-info .lt-block {
  width: 200px;
}

.msg-info {
  margin: 0;
  padding: 0;
}
.msg-info h2,
.msg-info h3 {
  font-size: 16px;
  line-height: 1.4;
  color: #2a3b5a;
  font-weight: 600;
  margin: 0;
  padding: 0;
}
.msg-info .up-text {
  margin: 0;
  padding: 0 0 25px 0;
  border-bottom: 1px solid #e6ebf1;
}
.msg-info .up-text h2 {
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.msg-info .opt-element {
  margin: 0;
  padding: 0;
}
.msg-info .opt-element ul li {
  margin: 0;
  padding: 25px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e6ebf1;
}

.msg-info .opt-element .label-block h3 {
  margin: 0;
  padding: 0;
}

.msg-info .opt-element .value-field {
  margin: 0;
  padding: 0;
}
.msg-info .opt-element .color-select {
  margin: 0;
  padding: 10px;
  position: relative;
}
.color-bg {
  display: inline-block;
  width: 37px;
  height: 32px;
  vertical-align: middle;
  margin: 0;
  padding: 0;
}

.msg-info .opt-element .color-select .color-box {
  width: 35px;
  height: 35px;
  position: relative;
  cursor: pointer;
  border-radius: 50%;
}
.msg-info .opt-element .color-select .photoshop-picker {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 9;
}

.color-col {
  margin: 0;
  padding: 0;
}
.color-col ol {
  margin: 0 -5px;
  padding: 0;
  display: flex;
  flex-flow: wrap;
  width: 250px;
}
.color-col ol li {
  display: block !important;
  width: 33.3%;
  margin: 0 0 10px !important;
  padding: 0 5px 0 !important;
  border: none !important;
}
.color-col .color-box {
  height: 35px;
  border: 1px solid #e6ebf1;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.color-col .color-box:hover {
  border: 1px solid #483bd0;
}
.color-col ol li.active .color-box {
  border: 1px solid #483bd0;
}

.msg-info .btn-block {
  margin: 15px 0 0 0;
  padding: 0;
  text-align: right;
}

.chatbot-chat{position:fixed;top:26%;right:4%;}
/* .chatbot-chat {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 15px;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
} */
.chat-element {
  width: 350px;
  max-height: 550px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #e6ebf1;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-flow: column;
}
.chat-element .top-block {
  margin: 0;
  padding: 15px;
  background: #133461;
  position: relative;
}
.chat-element .top-block .lt-block {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  border: none;
  flex-flow: inherit;
}
.chat-element .top-block .tpl-avatar-image {
  margin: 0 18px 0 0;
  padding: 0;
}
.chat-element .top-block .tpl-avatar-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.chat-element .top-block .text-block {
  margin: 0;
  padding: 0;
}
.chat-element .top-block .text-block h2 {
  color: #fff;
  line-height: 1;
  font-weight: 400;
  margin: 0;
  padding: 0;
}
.chat-element .top-block .text-block p {
  color: #d6d6d6;
  margin: 0;
  padding: 0;
}

.chat-element .top-block .close-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  margin: 0;
  padding: 0;
}
.chat-element .top-block .close-icon .icon-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  box-shadow: none;
  outline: none;
  margin: 0;
  padding: 0;
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 50%;
  opacity: 0.5;
}
.chat-element .top-block .close-icon .icon-btn img {
  width: 16px;
  height: auto;
}
.chat-element .top-block .close-icon .icon-btn:hover {
  opacity: 1;
  background: none;
}
.chat-element .top-block .close-icon .icon-btn:focus {
  background: none;
  opacity: 1;
}

.chat-element .conversation-text {
  flex: 1;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.conversation-text .upper-field {
  margin: 0;
  padding: 15px 30px;
}
.conversation-text .upper-field .box {
  margin: 0;
  padding: 0;
  border: 1px solid #e6ebf1;
  border-radius: 10px;
}
.conversation-text .upper-field .box .up-text {
  margin: 0;
  padding: 15px;
  background: #f7f5fa;
  border-radius: 10px 10px 0 0;
  position: relative;
}

.conversation-text .up-text .logo-icon {
  position: absolute;
  left: -12px;
  bottom: -12px;
  width: 25px;
  border-radius: 50%;
}
.conversation-text .up-text .logo-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.conversation-text .upper-field .box .up-text p {
  margin: 0;
  padding: 0;
}
.conversation-text .box .list-text {
  margin: 0;
  padding: 0;
}
.conversation-text .box .list-text ul li {
  font-size: 15px;
  line-height: 1.5;
  color: #586f8e;
  font-weight: 400;
  margin: 0;
  padding: 0;
  border-top: 1px solid #e6ebf1;
}
.conversation-text .box .list-text ul li a {
  display: block;
  color: #586f8e;
  margin: 0;
  padding: 10px 15px;
}
.conversation-text .box .list-text ul li:hover a {
  color: #133461;
}

.conversation-text .chat {
  border: none;
}
.symmetrical {
  margin: 0;
  padding: 0;
}

.symmetrical .msg {
  margin: 0;
  padding: 0 15px;
  display: flex;
  margin-bottom: 15px;
}
.symmetrical .msg .bubble {
  max-width: 75%;
  position: relative;
  margin: 0;
  padding: 0;
}
.symmetrical .msg .bubble .bubble-wrapper {
  padding: 10px 15px;
  border-radius: 0.625rem;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 10px;
}
.symmetrical .msg.msg-recipient .bubble .bubble-wrapper {
  background: #ededed;
  color: #1a3353;
}

.symmetrical .msg.msg-sent {
  justify-content: flex-end;
}
.msg.msg-sent .bubble .bubble-wrapper ::selection {
  background-color: #16145b !important;
}
.msg.msg-sent .bubble .message-time ::selection {
  background-color: #16145b !important;
}
.symmetrical .msg.msg-sent .bubble .bubble-wrapper {
  background-color: #3e79f7;
  color: #fff;
}

.chat-element .bottom-field {
  margin: 0;
  padding: 0;
}
.chat-element .bottom-field p {
  font-size: 12px;
  line-height: 1;
  color: #000;
  font-weight: 400;
  margin: 0;
  padding: 10px;
  text-align: center;
}
.chat-element .bottom-field p a {
  color: #637896;
}
.chat-element .bottom-field p a:hover {
  color: #133461;
}

.chat-element .typing-field {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  border-top: 1px solid #e6ebf1;
}
.chat-element .typing-field .hamburger-menu {
  margin: 0;
  padding: 0;
}
.chat-element .typing-field .menu-btn {
  margin: 0;
  padding: 5px 15px;
  border: none;
  box-shadow: none;
  outline: none;
  background: none;
  color: #133360;
}

.chat-element .typing-field .input-box {
  flex: 1;
  margin: 0;
  padding: 0;
}
.chat-element .typing-field .input-box .ant-input {
  width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  height: 50px;
  font-size: 16px;
}

.chat-element .typing-field .send-box {
  margin: 0;
  padding: 0;
}
.chat-element .typing-field .send-box .send-btn {
  border: none;
  outline: none;
  box-shadow: none;
  background: none;
  padding: 5px 15px;
  font-size: 20px;
  transition: all 300ms ease-in-out;
}
.chat-element .typing-field .send-box .send-btn:hover {
  background: none;
  color: #133461;
}
.chat-element .typing-field .send-box .send-btn .anticon {
  transition: all 300ms ease-in-out;
  opacity: 0.5;
}
.chat-element .typing-field .send-box .send-btn:hover .anticon {
  transform: rotate(-45deg);
  opacity: 1;
}

/*=============== Message End ===============*/

/*=============== WhatApp Template Start ===============*/
.content-wrap .top-filter.whtp-search-filter {
  margin-bottom: 20px;
  justify-content: space-between;
}
.whtp-search-filter .search-filter {
  width: 50%;
  margin-bottom: 0;
}

.chrt-item {
  position: relative;
}
.chrt-item .prv-btn {
  position: absolute;
  top: -35px;
  right: 0;
  background: none;
  border: none;
  box-shadow: none;
  outline: none;
  color: #6583fe;
  height: auto;
  padding: 5px 0;
}
.chrt-item .prv-btn:hover {
  background: none !important;
  color: #6583fe !important;
}

.prv-base-drawer .ant-drawer-body {
  background: url(../src/assets/images/whtp-bg-img.jpg) no-repeat left top;
  background-size: cover;
}

.preview-block {
  margin: 0;
  padding: 0;
}
.preview-block .content-box {
  margin: 0 0 10px 0;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
}
.preview-block .btn-block {
  margin: 0;
  padding: 0;
}
.preview-block .btn-block .ant-btn {
  width: 100%;
  margin-bottom: 10px;
  background: #fff;
}
.preview-block .btn-block .ant-btn:hover {
  background: #fff !important;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.content-wrap .top-filter.cnm-filter {
  justify-content: space-between;
}

.content-wrap .top-filter .lt-multifield .ant-form {
  display: flex;
}
.content-wrap .top-filter .lt-multifield .ant-form-item {
  margin-bottom: 0;
}
.content-wrap .top-filter .lt-multifield .ant-btn {
  margin-left: 15px;
}
.content-wrap .top-filter.cnm-filter .search-box {
  width: 350px;
}

/*=============== WhatApp Template End ===============*/

/*=============== Business Hours Start ===============*/
.swt-item {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* .swt-item .ant-switch-checked{ background:#04D182; } */

.tabcontent-wrap.custm-tabcontent {
  height: 100%;
  position: relative;
}
.tabcontent-wrap.custm-tabcontent .ant-empty {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}

.add-element-field .block {
  margin: 25px 0 0 0;
  padding: 0;
}
.add-element-field .block h4 {
  font-size: 14px;
  line-height: 1;
  color: #1a3353;
  font-weight: 500;
  margin: 0;
  padding: 0 0 25px 0;
}
.add-element-field .list-field li {
  margin: 0 0 25px 0;
  padding: 0;
}
.add-element-field .list-field .align-center {
  align-items: center;
}

.add-element-field .list-field .ant-select {
  width: 100%;
}
.add-element-field .list-field .col-sub-text {
  display: block;
  text-align: center;
}

.add-element-field .list-field .ant-checkbox-group {
  margin-top: 20px;
  margin-left: 28px;
}

.bsn-btn-block {
  margin: 0;
  padding: 0;
}
.bsn-btn-block .ant-btn {
  margin-right: 15px;
}

/*=============== Business Hours End ===============*/

/*=============== Organization Start ===============*/
.orgn-element-info {
  margin: 0;
  padding: 25px;
  display: block;
}
.orgn-element-info .steps-field {
  margin: 25px 0 65px 0;
  padding: 0;
}
.orgn-element-info .steps-field .ant-steps-item-finish .ant-steps-item-icon {
  border-color: #89dba8;
}
.orgn-element-info
  .steps-field
  .ant-steps-item-finish
  .ant-steps-item-icon
  .ant-steps-icon {
  color: #89dba8;
}

.orgn-element-info .whta-info {
  background: #fff;
  margin: 0;
  padding: 30px;
}

.orgn-element-info .ant-picker {
  width: 100%;
}

/*=============== Organization End ===============*/

/*=============== Subscription Start ===============*/
.sbcpn-info {
  margin: 0;
  padding: 30px;
}

.sbcpn-info .block {
  margin: 0 0 60px 0;
  padding: 0;
}
.sbcpn-info .heading-block {
  margin: 0;
  padding: 0 0 15px 0;
  border-bottom: 1px solid #e6ebf1;
}
.sbcpn-info .heading-block h3 {
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.sbcpn-info .content-field {
  margin: 0;
  padding: 0;
}
.sbcpn-info .content-field .field {
  margin: 0;
  padding: 20px 0;
  display: flex;
}
.sbcpn-info .field .text-block {
  max-width: 250px;
  margin: 0;
  padding: 0;
}
.sbcpn-info .field .text-block h4 {
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
  margin: 0;
  padding: 0 0 8px 0;
}
.sbcpn-info .field .text-block p {
  font-size: 12px;
}

.sbcpn-info .field .btn-block {
  width: 250px;
  margin: 0 0 0 auto;
  padding: 0;
}
.sbcpn-info .field .btn-block .ant-btn {
  width: 100%;
}
.sbcpn-info .field .btn-block .cancel-btn {
  margin-top: 15px;
}

.sbcpn-info .content-field ul li {
  margin: 0;
  padding: 20px 0;
  display: flex;
  align-items: center;
}

.sbcpn-info .content-field .card-text {
  margin: 0;
  padding: 0 45px 0 0;
  display: flex;
  align-items: center;
}
.sbcpn-info .card-text .icon {
  display: inline-block;
  width: 20px;
  margin: 0;
  padding: 0;
}
.sbcpn-info .card-text .icon img {
  width: 100%;
  height: auto;
}
.sbcpn-info .card-text p {
  font-weight: 700;
  color: #1a3353;
  margin: 0 5px;
  padding: 0;
}

.sbcpn-info .content-field .date-text {
  margin: 0;
  padding: 0 35px;
}
.sbcpn-info .content-field .date-text p {
  font-weight: 500;
}
.sbcpn-info .content-field .close-box {
  margin: 0;
  padding: 0;
}
.sbcpn-info .content-field .close-icon {
  border: none;
  width: auto;
  height: auto;
  padding: 5px;
  border: none;
  outline: none;
  background: none !important;
  font-size: 12px;
}
.sbcpn-info .content-field .close-icon:after {
  display: none;
}

.sbcpn-info .content-field .add-btn {
  margin: 0;
  padding: 0;
}
.sbcpn-info .content-field .add-payment {
  color: #455560;
  background: none !important;
  padding: 5px 0;
  font-weight: 500;
}
.sbcpn-info .content-field .add-payment:hover {
  color: #3e79f7;
}

.billing-table {
  margin: 0;
  padding: 0;
  max-width: 500px;
}
.billing-table table {
  width: 100%;
}
.billing-table table tr td {
  font-size: 14px;
  line-height: 1.8;
  font-weight: 500;
  color: #455560;
  margin: 0;
  padding: 10px 0 10px 0;
}

.plan-modal {
  width: 700px !important;
}
.plan-modal .ant-modal-footer {
  display: none;
}

.upd-element {
  margin: 0;
  padding: 0;
}
.upd-element ul li {
  margin: 0;
  padding: 20px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6ebf1;
}
.upd-element ul li:last-child {
  border-bottom: none;
}
.upd-element .text-block {
  flex: 1;
  margin: 0;
  padding: 0 35px 0 0;
}

.upd-element .btn-block {
  margin: 0 0 0 auto;
  padding: 0;
}
.upd-element .btn-block .ant-btn {
  width: 150px;
}

/*=============== Subscription End ===============*/

/*=============== Chat Assignment Start ===============*/
.chat-routing-field {
  margin: 0;
  padding: 0;
}

.chat-routing-field .field-block {
  margin: 0;
  padding: 0;
}

.chat-routing-field .btn-align {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.chat-routing-field .btn-align .ant-btn {
  width: 135px;
  background: #ededed;
  margin-right: 25px;
}

.chat-routing-field .rtn-element {
  margin: 0 0 0 67px;
  padding: 35px 0 0 0;
  border-left: 1px solid #e7ebf0;
}

.chat-routing-field .rtn-element .ant-form-item {
  margin-bottom: 0;
}

.rtn-element .block {
  margin: 0;
  padding: 0 25px 0 30px;
  position: relative;
  position: relative;
}
.rtn-element .block .form-block {
  margin: 0;
  padding: 10px 15px;
  background: #fafafb;
  position: relative;
}
.rtn-element .block .form-block:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -30px;
  width: 25px;
  height: 1px;
  background: #e7ebf0;
}

.rtn-element .block .input-box {
  margin: 0;
  padding: 0;
}
.rtn-element .block .input-box .ant-select {
  width: 100%;
}

.rtn-element .block .switch-field {
  margin: 0;
  padding: 15px;
  text-align: center;
}
.rtn-element .block .switch-field .ant-radio-button-wrapper.and-btn:hover {
  color: #009688;
}
.rtn-element .block .switch-field .ant-radio-button-wrapper.or-btn:hover {
  color: #ffa000;
}
.rtn-element .block .switch-field .ant-radio-button-wrapper-checked.and-btn {
  background: #009688;
  color: #fff;
  border-color: transparent;
}
.rtn-element
  .block
  .switch-field
  .ant-radio-button-wrapper-checked.and-btn:hover {
  color: #fff;
}
.rtn-element .block .switch-field .ant-radio-button-wrapper-checked.or-btn {
  background: #ffa000;
  color: #fff;
  border-color: transparent;
}
.rtn-element
  .block
  .switch-field
  .ant-radio-button-wrapper-checked.or-btn:hover {
  color: #fff;
}
.rtn-element
  .block
  .switch-field
  .ant-radio-button-wrapper-checked.or-btn::before {
  background: transparent;
}

.rtn-element .block .add-field {
  margin: 0;
  padding: 10px 15px;
  text-align: center;
}
.rtn-element .block .add-field .add-btn {
  margin: 0;
  padding: 5px;
  border-radius: 50%;
  border: none;
  box-shadow: none;
  outline: none;
  font-size: 22px;
}
.rtn-element .block .add-field .add-btn:after {
  display: none;
}
.rtn-element .block .add-field .add-btn:hover,
.rtn-element .block .add-field .add-btn:focus {
  background: none;
  color: #4d78ef;
}

.rtn-element .block .form-block.assign-form {
  padding: 0;
  background: none;
}
.rtn-element .block .form-block.assign-form:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 20px;
  background: #fff;
  left: -32px;
  bottom: 0;
}

.rtn-element .block .remove-btn {
  position: absolute;
  right: 0;
  top: 26px;
}

.rtn-element .block:after {
  content: "";
  position: absolute;
  left: -2px;
  width: 4px;
  bottom: 0;
  height: 89px;
  background: transparent;
}

.rtn-element .ant-form-item:last-child .block:after {
  background: #fff;
}

.rtn-element .ant-form-item:last-child .block {
  padding-right: 0;
}

.add-element-field .btn-block {
  margin: 0;
  padding: 30px 0;
}

/*=============== Chat Assignment End ===============*/

/*=============== Contacts Page Start ===============*/
.conts-user-table {
  overflow: hidden;
}

.sgm-item {
  display: block;
}
.sgm-item label:after {
  display: none;
}

/*=============== Contacts Page End ===============*/

/*=============== Inbox Page Start ===============*/
.tab-field-element .lt-block.inb-block {
  width: 200px;
  position: relative;
}

.dropdown-box .filter-dropdown {
  transform: rotate(0);
  font-size: 17px;
}

.inb-block .scroll-view {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.labels-item {
  margin: 0;
  padding: 0;
}
.labels-item h5 {
  font-size: 14px;
  line-height: 40px;
  font-weight: 500;
  color: #798496;
  text-transform: uppercase;
  margin: 0;
  padding: 15px;
  padding-bottom: 0;
}

.labels-item ul li {
  font-size: 14px;
  line-height: 40px;
  font-weight: 500;
  color: #455560;
  position: relative;
  min-height: 40px;
  margin: 4px 0 8px;
  padding: 0 15px;
  cursor: pointer;
}
.labels-item ul li .color-dotted {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 5px 0 0;
  padding: 0;
}

.tabcontent-wrap.inbox-wrap {
  height: 100%;
  padding: 0;
}
.inbox-wrap .chat {
  height: 100%;
  border-radius: 0;
  border: none;
}
.inbox-wrap .chat .inner-app-layout {
  height: 100%;
}

.inbox-wrap .chat .ant-tabs {
  width: 100%;
  position: relative;
}
.inbox-wrap .chat .ant-tabs .ant-tabs-content {
  height: 100%;
}

.inbox-wrap .chat .ant-tabs-nav {
  position: absolute;
  width: 37%;
  top: 0;
  left: 0;
  z-index: 9;
}
.inbox-wrap .chat .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 19px 8px;
  height: 56px;
}

.inbox-wrap .chat .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 12px;
}

.tab-content-info {
  height: 100%;
  display: flex;
  margin: 0;
  padding: 0;
}
.tab-content-info .side-content {
  width: 37%;
  border-right: 1px solid #edf2f9;
  padding-top: 56px;
}

.tab-content-info .main-content {
  flex: 1;
  margin: 0;
  padding: 0;
  border-right: 1px solid #edf2f9;
  position: relative;
}

.default-text-content {
  position: absolute;
  top: 80%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
}
.default-text-content h2 {
  font-size: 35px;
  line-height: 1;
  font-weight: 700;
  margin: 0;
  padding: 0 0 15px 0;
}
.default-text-content p {
  font-size: 18px;
  line-height: 1;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.inbox-wrap .chat-menu .chat-menu-toolbar {
  height: 48px;
}
.inbox-wrap .chat-menu .switch-box {
  height: 30px;
  padding: 4px;
  display: grid;
  grid-template-columns: 12fr 2fr;
}
.inbox-wrap .chat-menu .switch-box span {
  color: #cbd3dc;
  font-size: 13px;
}
.inbox-wrap .chat-menu .chat-menu-toolbar .search-box {
  flex: 1;
  margin: 0;
  padding: 0;
}
.inbox-wrap .chat-menu .chat-menu-toolbar .ant-input {
  font-size: 16px;
}

.inbox-wrap .chat-menu .chat-menu-toolbar .dropdown-box {
  margin-right: 5px;
}

.inbox-wrap .chat-menu .chat-menu-list {
  height: calc(100vh - 150px);
}

.inbox-wrap
  .chat-menu
  .chat-menu-list
  .chat-menu-list-item
  .avatar-status
  .avatar-status-subtitle {
  width: auto;
  max-width: 150px;
  margin-bottom: 4px;
}

.inbox-wrap .chat-menu .chat-menu-list .avatar-status-name .anticon {
  color: gray;
  margin-right: 5px;
  font-size: 16px;
}
.inbox-wrap .chat-menu .chat-menu-list .avatar-agn-name {
  font-size: 12px;
}

.avatar-agn-name .ant-checkbox-wrapper {
  margin-right: 5px;
}

.inbox-wrap .chat-menu .chat-menu-list .ant-avatar {
  display: none;
}
.inbox-wrap .chat-menu .chat-menu-list .ml-2 {
  margin-left: 0 !important;
}

.inbox-wrap .chat-menu .chat-menu-list span.tag-text {
  display: block;
  padding-top: 3px;
}

.inbox-wrap .chat-content-header {
  height: 56px !important;
}
.inbox-wrap .chat-content-header .ant-form-item {
  margin-bottom: 0;
}

.inbox-wrap .chat-content-header .pending-btn {
  background: #24c324;
  color: #fff;
}
.inbox-wrap .chat-content-header .close-btn {
  background: #ff6b72;
  color: #fff;
}

.inbox-wrap .chat-content {
  display: flex;
  flex-flow: column;
}
.inbox-wrap .chat-content .chat-content-body {
  flex: 1;
}

.inbox-wrap .ant-divider-horizontal.ant-divider-with-text::after,
.inbox-wrap .ant-divider-horizontal.ant-divider-with-text::before {
  top: 0;
}

.inbox-wrap .chat-content .chat-content-body .ant-divider-inner-text {
  color: rgba(114, 132, 154, 0.7) !important;
}

.inbox-wrap .chat-content .ant-input-group {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #edf2f9;
  border-radius: 6px;
  padding-right: 10px;
}
.inbox-wrap .chat-content textarea.ant-input {
  resize: none;
  height: 40px;
  border-color: transparent;
}

/* height: calc(100vh - 60px - 76px - 25px - 70px - 60px - 45px); */
.inbox-wrap .chat .chat-content .chat-content-body {
  height: calc(100vh - 60px - 76px - 25px - 70px - 60px - 45px);
  position: relative;
}
.inbox-wrap .chat .chat-content .chat-content-body .msg {
  position: relative;
}
.inbox-wrap .chat .chat-content .chat-content-body .msg .message-time {
  display: block;
  margin: 0;
  padding: 0;
  text-align: right;
  font-size: 10px;
}
.inbox-wrap .chat .chat-content .chat-content-body .msg .message-agent {
  display: block;
  margin: 0;
  padding: 0;
  text-align: right;
  color: #495964;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  font-size: 13px;
}

.inbox-wrap .chat .chat-content .chat-content-body .chat-unseen-msg {
  border-style: none;
  position: fixed;
  top: 19%;
  right: 43%;
  width: 170px;
  font-size: 12px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  padding: 6px;
  padding: 5px;
  border-radius: 35px;
  cursor: pointer;
  z-index: 100000;
  height: 30px;
  color: rgb(255 255 255);
  background-color: #4e84f7;
}

.inbox-wrap .chat .rt-avatar {
  width: 25%;
  margin: 0;
  padding: 15px 20px;
}
.inbox-wrap .chat .rt-avatar .ant-avatar {
  margin-bottom: 15px;
}

.inbox-wrap .chat .rt-avatar h4 {
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
  color: #203251;
  margin: 0;
  padding: 0 0 6px 0;
}
.inbox-wrap .chat .rt-avatar p {
  font-size: 12px;
  line-height: 1;
  font-weight: 400;
  color: #a7afbd;
  margin: 0;
  padding: 0 0 3px 0;
}

.inbox-wrap .chat-menu .chat-menu-list .chat-menu-list-item {
  padding: 5px 20px;
  border-left: 5px solid #ffffff;
}
.inbox-wrap .chat-menu .chat-menu-list .chat-menu-list-item.active {
  background-color: #f5f5f5;
}
/* .inbox-wrap .chat-menu .chat-menu-list .chat-menu-list-item:nth-child(2){ border-left-color:#24C324; }
.inbox-wrap .chat-menu .chat-menu-list .chat-menu-list-item:nth-child(3){ border-left-color:#3E79F7; } */
.chat-menu-list-badges {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 3px;
}
.chat-menu-list-badges-outer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.user-info {
  margin: 0;
  padding: 0;
}
.user-info .block {
  margin: 0;
  padding: 0;
}
.user-info .block ul li {
  margin: 0 0 15px 0;
  padding: 0;
  font-size: 14px;
  line-height: 1;
  color: rgba(114, 132, 154, 0.7) !important;
  display: flex;
}
.user-info .block .label-text {
  display: inline-block;
  width: 85px;
  margin: 0;
  padding: 0;
}
.user-info .block .value-text {
  flex: 1;
  color: #1a3353;
  line-height: 1.4;
  font-weight: 700;
}

.user-info .block .ant-form-item .ant-form-item-label label {
  height: auto;
  line-height: 1;
  padding-bottom: 9px;
}
.user-info .block .ant-form-item .ant-form-item-label .edit-btn {
  background: none;
  border: none;
  box-shadow: none;
  outline: none;
  margin: 0 0 0 7px;
  padding: 0;
  height: auto;
}
.user-info .block .ant-form-item .ant-form-item-label .edit-btn:after {
  display: none;
}

.user-info .block.top-block {
  display: flex;
  align-items: center;
}
.user-info .block.top-block .ant-avatar {
  margin-right: 12px;
}
.user-info .block.top-block .desc-text {
  flex: 1;
}
.user-info .block.top-block .desc-text h2 {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 3px;
}
.user-info .block.top-block .desc-text .edit-btn {
  background: none;
  border: none;
  box-shadow: none;
  outline: none;
  margin: 0 0 0 7px;
  padding: 0;
  height: auto;
}
.user-info .block.top-block .desc-text span {
  display: block;
}
.user-info .block.top-block .desc-text .edit-btn:after {
  display: none;
}

.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  font-size: 11px;
  line-height: 20px;
}

.inbox-wrap .chat .ant-tabs .ant-tabs-extra-content {
  margin-right: 16px;
}

.attach-field {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.attach-field .ant-avatar {
  background: #545454;
}
.attach-field .text-line {
  flex: 1;
  margin: 0 15px 0 0;
  padding: 0;
}
.attach-field .text-line p {
  margin: 0;
  padding: 0;
  line-height: 1.4;
}

.attach-field .btn-block {
  margin: 0;
  padding: 0;
}
.attach-field .btn-block .dwn-btn {
  padding: 5px 15px;
}

.slider-bubble {
  background: none !important;
  width: 350px;
}
.slider-block {
  margin: 0;
  padding: 0;
}
.slider-block .item {
  width: 300px !important;
  margin: 0;
  padding: 0 15px 0 0;
}

.slider-block .box {
  margin: 0;
  padding: 0;
  background: #fafafb;
  border-radius: 6px;
  overflow: hidden;
}
.slider-block .item .img-block {
  margin: 0;
  padding: 0;
  height: 180px;
}
.slider-block .item .img-block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slider-block .item .text-block {
  margin: 0;
  padding: 0;
}

.slider-block .item .top-text {
  margin: 0;
  padding: 15px;
}
.slider-block .item .text-block h2 {
  font-size: 17px;
  margin: 0;
  padding: 0 0 5px 0;
}
.slider-block .item .text-block .ant-btn {
  width: 100%;
  display: block;
}

.slider-block .ant-carousel .slick-next,
.slider-block .ant-carousel .slick-prev {
  width: 25px;
  height: 25px;
  background: #364d79;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  z-index: 1;
}
.slider-block .ant-carousel .slick-next:before,
.slider-block .ant-carousel .slick-prev:before {
  display: none;
}

.slider-block .ant-carousel .slick-next {
  right: -11px;
  background: url(../src/assets/images/next-arrow-icon.svg) no-repeat center
    #364d79;
  background-size: 5px;
}

.slider-block .ant-carousel .slick-prev {
  left: -11px;
  background: url(../src/assets/images/prev-arrow-icon.svg) no-repeat center
    #364d79;
  background-size: 5px;
}

.inbox-wrap .chat-content .chat-content-footer {
  position: relative;
}
.inbox-wrap .chat-content .chat-content-footer .input-textarea:focus {
  border: none;
  border-right-width: 0px !important;
  box-shadow: none;
}
.inbox-wrap .chat-content .chat-content-footer .sfield-box {
  position: absolute;
  bottom: 100px;
  left: 20px;
  background: #fff;
  border: 1px solid #edf2f9;
  border-radius: 6px;
  width: 350px;
  max-height: 250px;
  overflow: auto;
  z-index: 9;
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
}
.inbox-wrap .chat-content .chat-content-footer .sfield-box-tag-subtag {
  position: absolute;
  bottom: 100px;
  left: 20px;
  background: #fff;
  border: 1px solid #edf2f9;
  border-radius: 6px;
  width: 250px;
  max-height: 250px;
  overflow: auto;
  z-index: 9;
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
  padding-top: 5px;
}
/* .inbox-wrap .chat-content .chat-content-footer .sfield-box-tag-subtag li{border:1px solid #EDF2F9; border-radius:6px; margin-bottom: 3px;}
.inbox-wrap .chat-content .chat-content-footer .sfield-box-tag-subtag li:hover{ background-color: #EDF2F9; border:1px solid #EDF2F9; border-radius:6px;} */

.inbox-wrap .chat-content .chat-content-footer .btn {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  box-shadow: none;
  height: auto;
  background: none !important;
}
.inbox-wrap .chat-content .chat-content-footer .btn:after {
  display: none;
}

.inbox-wrap .chat-content .chat-content-footer .btn-group {
  position: relative;
}
.inbox-wrap .chat-content .chat-content-footer .btn-group .emoji-picker-react {
  position: absolute;
  right: 0;
  bottom: 100%;
}
.whatsapp-template-form .chrt-item .emoji-picker-react {
  position: absolute;
  right: 0;
  bottom: 100%;
}

.inbox-wrap .chat-content-footer .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-right: 1rem !important;
}

.inbox-wrap .chat-content-footer .upload-btn-wrapper .btn {
  cursor: pointer;
  margin-right: 0 !important;
}

.inbox-wrap .chat-content-footer .upload-btn-wrapper input[type="file"] {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.sfield-box ul li {
  margin: 0;
  padding: 10px 15px;
  border-bottom: 1px solid #edf2f9;
  cursor: pointer;
}
.sfield-box ul li:hover {
  background: #fafafb;
}
.sfield-box h4 {
  font-size: 14px;
}
.sfield-box p {
  font-size: 12px;
}

/*=============== Inbox Page End ===============*/

/*=============== Tags Page Design Create ===============*/
.tag-modal .ant-modal-body {
  position: relative;
}
.tag-modal .ant-modal-body .base-form {
  padding-bottom: 45px;
}

.tag-modal .ant-modal-footer {
  display: none;
}
.tag-modal .ant-modal-footer.btn-block {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

/*=============== Tags Page Design End ===============*/

/*=============== Bot-Builder Page Start ===============*/
.base-bot-info {
  height: 100%;
  background-color: #fafafb;
}
.base-bot-info .react-flow__node.react-flow__node-quickReplyNode {
  z-index: 11 !important;
}
/* .base-bot-info .react-flow__pane, .base-bot-info .react-flow__renderer, .base-bot-info .react-flow__selectionpane{ width:1000px; right:0; margin:a auto; } */

.base-bot-info .react-flow__pane {
  z-index: 0 !important;
}

.base-bot-info .react-flow__node-input {
  border: none;
  padding: 0;
}
.base-bot-info .react-flow__controls {
  left: auto;
  right: 15px;
}

.card-box {
  width: 350px;
  margin: 0;
  padding: 0;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  position: relative;
  background: #fff;
}
.card-box .top-block {
  margin: 0;
  padding: 15px;
  background: #483ad0;
  border-radius: 6px 6px;
  text-align: center;
}
.card-box h2 {
  font-size: 15px;
  line-height: 1;
  color: #fff;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.card-box .content {
  margin: 0;
  padding: 8px 16px;
  position: relative;
}
.card-box .content p {
  font-size: 14px;
  line-height: 1.5;
  color: #1a3353;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.card-box .content > div:not(.card-info):first-child {
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.card-box .content textarea.ant-input:not(.addButtonTxt) {
  resize: none;
}
.card-box .content textarea.ant-input:focus {
  border-bottom-color: #e6ebf1;
}

.card-box .setting-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none !important;
  border: none;
  width: auto;
  height: auto;
  outline: none;
  font-size: 15px;
  color: #808080;
  margin: 0;
  padding: 0;
}
.card-box .setting-btn:after {
  display: none;
}

.card-box .delete-box {
  position: absolute;
  right: -35px;
  top: 0;
}
.card-box .delete-box .delete-btn {
  width: 30px;
  height: 30px;
  border: none;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  color: #ff6b72;
  margin: 0;
  padding: 0;
  visibility: hidden;
  opacity: 0;
}
.card-box .delete-box .delete-btn:hover {
  background: rgba(0, 0, 0, 0.1) !important;
  color: #ff6b72 !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1) !important;
}

.card-box:hover .delete-box .delete-btn {
  visibility: visible;
  opacity: 1;
}

.node-add-btn {
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  text-align: center;
}
.node-add-btn .ant-btn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 auto;
  padding: 0;
  font-size: 12px;
  background: #483ad0;
  border: none;
  outline: none;
  box-shadow: none;
}
.node-add-btn .ant-btn:after {
  display: none;
}

.card-box .heading-block {
  margin: 0;
  padding: 8px 15px 0;
}
.card-box .heading-block h2 {
  color: #483ad0;
  display: flex;
  align-items: center;
}
.card-box .heading-block h2 svg {
  color: #808080;
  margin-right: 5px;
}
.card-box .heading-block h2 .anticon {
  color: #808080;
}

.card-box .heading-block h2 .edit-btn {
  border: none;
  box-shadow: none;
  outline: none;
  width: auto;
  height: auto;
  background: none !important;
  margin: 0 0 0 5px;
  padding: 0;
}
.card-box .heading-block h2 .edit-btn:after {
  display: none;
}

.card-box .heading-block h2 .static-text {
  display: inline-block;
  margin: 0 5px 0 0;
}
.card-box .heading-block .ant-input {
  width: 100px;
  height: 30px;
}

.search-dropdown {
  margin: 8px 20px;
  padding: 0;
  position: relative;
}
.search-dropdown .ant-input {
  padding-left: 30px;
}
.search-dropdown .ant-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background: none !important;
  border: none;
  box-shadow: none;
  outline: none;
  width: 30px;
  height: auto;
  margin: 0;
  padding: 0;
}
.search-dropdown .ant-btn:after {
  display: none;
}

.add-test-box {
  margin: 10px 0 0 0;
  padding: 0;
  position: relative;
}
.add-test-box ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
}
.add-test-box ul li {
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: text-top;
  margin-right: 10px;
}
.add-test-box ul li:last-child {
  margin-right: 0;
}

.add-test-box.choice ul li {
  width: 280px;
  margin-right: 0px;
  margin-bottom: 10px;
}
.add-test-box.choice ul li span {
  width: 100%;
}

.add-test-box.choice ul {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.add-test-box ul li .tag-box {
  display: inline-block;
  font-size: 14px;
  line-height: 1;
  color: #483ad0;
  font-weight: 400;
  margin: 0;
  padding: 0;
}
.add-test-box ul li .tag-box textarea {
  text-align: center;
}
.add-test-box ul li .tag-box .ant-input {
  width: 110px;
  border-radius: 25px;
  resize: none;
  background: #fff;
  border: 1px solid #483ad0;
  color: #483ad0;
}
.add-test-box.choice ul li .tag-box .ant-input {
  width: 100%;
}

.add-test-box .node-add-btn {
  position: relative;
  bottom: 0;
  margin-top: 3px;
}

.card-box.condition-box {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 100px;
  height: 100px;
  display: inline-block;
  margin-top: 24px;
  margin-bottom: 10px;
  background-color: rgb(28, 0, 95);
}
.condition-box-container {
  text-align: center;
}
.condition-box .left-handle {
  background: rgb(85, 85, 85);
  z-index: 1000;
  position: absolute;
  top: 95px !important;
  left: -1px !important;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.condition-box .right-handle {
  background: rgb(85, 85, 85);
  z-index: 1000;
  position: absolute;
  bottom: 95px !important;
  left: 95px !important;
  top: initial !important;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.card-box.card-node {
  width: auto;
}
.card-box.card-node .content {
  padding: 15px 30px;
  padding-right: 0;
  display: flex;
  align-items: center;
}
.card-info {
  margin: 0 0 35px 0;
  padding: 0;
  display: flex;
}
.card-info .thumb {
  margin: 0 85px 0 0;
  padding: 0;
  position: relative;
}
.card-info .thumb:last-child {
  margin-right: 0;
}

.card-info .box {
  width: 230px;
  margin: 0;
  padding: 0;
  border: 1px solid #483ad0;
  border-radius: 8px;
}
.card-info .box .img-block {
  margin: 0;
  padding: 0;
  height: 130px;
  overflow: hidden;
  background: #eff0f2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 8px 0 0;
  position: relative;
}
.card-info .box .img-block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card-info .box .img-block .anticon {
  font-size: 70px;
  color: #808990;
}

.card-info .box .img-block .edit-box {
  position: absolute;
  top: 5px;
  right: 5px;
}
.card-info .box .img-block .edit-box .edit-btn {
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  margin: 0;
  padding: 0;
}
.card-info .box .img-block .edit-box .edit-btn img {
  width: 14px;
  height: auto;
}

.card-info .box .text-block {
  margin: 0;
  padding: 0;
}
.card-info .box .text-block ul li {
  position: relative;
}
.card-info .box .text-block ul li:nth-child(3) > div.connector {
  position: absolute;
  left: 100%;
  top: 24px;
  border-right: 1px dashed #b1b1b7;
  width: 60px;
  border-top: 1px dashed #b1b1b7;
  height: 170px;
}
.card-info .box .text-block ul li:nth-child(4) > div.connector {
  position: absolute;
  left: 100%;
  top: 24px;
  border-right: 1px dashed #b1b1b7;
  width: 20px;
  border-top: 1px dashed #b1b1b7;
  height: 120px;
}

.card-info .box .text-block ul li:nth-child(5) > div.connector {
  position: absolute;
  right: 20px;
  top: 50px;
  border-right: 1px dashed #b1b1b7;
  width: 20px;
  border-top: 1px dashed #b1b1b7;
  height: 43px;
}

.card-info .box .text-block ul li:nth-child(5) div.node-add-btn {
  top: 90px;
  right: 11px;
}

.card-info .box .text-block ul li:nth-child(4) div.node-add-btn {
  top: 141px;
  right: -30px;
}

.card-info .box .text-block ul li:nth-child(3) div.node-add-btn {
  top: 192px;
  right: -69px;
}

.card-info .box .text-block ul li {
  font-size: 14px;
  line-height: 1.5;
  color: #333;
  font-weight: 400;
  margin: 0;
  padding: 5px 15px;
  text-align: center;
  border-top: 1px solid #483ad0;
  position: relative;
}

.card-info .box .text-block ul li .btn-drop {
  position: absolute;
  width: 5px;
  height: 5px;
  bottom: auto;
  top: 70%;
  left: -17px;
  right: auto;
  z-index: 999;
}
.card-info .box .text-block ul li .node-add-btn {
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
  left: initial !important;
  right: -25px;
}

.card-info .box .text-block .btn-drop .overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000;
}

.card-info .box .text-block ul li textarea.ant-input {
  text-align: center;
  overflow: hidden;
}

.card-info .box .btn-block {
  margin: 0;
  padding: 0;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
}
.card-info .box .btn-block .add-btn {
  display: block;
  width: 100%;
  border-radius: 0;
  background: #eff0f2;
  color: #000;
  border: none;
  outline: none;
}

.card-node .content .add-btn-box {
  width: 90px;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}
.card-node .content .add-btn-box .node-add-btn {
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  width: 48px;
  height: 48px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #483ad0;
  border-radius: 10px;
  background: #fafbf4;
}

.base-modal .ant-modal-body {
  padding: 0;
}
.base-modal .ant-modal-body .card-box {
  width: 100%;
}
.base-modal .card-box .content {
  position: relative;
}
.base-modal .card-box .content .ant-input {
  border: 1px solid #e6ebf1;
  border-radius: 6px;
  padding: 10px;
}

.base-modal .ant-modal-close {
  right: -40px;
}
.base-modal .ant-modal-close .ant-modal-close-x {
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #000;
}

.base-modal .card-box .content .emoji-field {
  position: absolute;
  right: 22px;
  bottom: 20px;
}
.base-modal .card-box .content .emoji-field .emoji-btn {
  border: none;
  box-shadow: none;
  outline: none;
  background: none;
  height: auto;
  margin: 0;
  padding: 0;
}

.tox-toolbar {
  margin: 0;
  padding: 0 15px 15px;
  display: flex;
  align-items: center;
}
.tox-toolbar .tox-group {
  margin: 0;
  padding: 0;
}

.tox-toolbar .tox-group .ant-btn {
  border: none;
  box-shadow: none;
  outline: none;
  background: none;
  font-size: 17px;
  line-height: 1;
  color: #000;
  margin: 0 5px 0 0;
  padding: 5px;
  height: auto;
}
.tox-toolbar .tox-group .ant-btn:last-child {
  margin-right: 0;
}

.tox-toolbar .tox-group .bold-text span {
  font-weight: 700;
}
.tox-toolbar .tox-group .italic-text span {
  font-style: italic;
}
.tox-toolbar .tox-group .underline-text span {
  text-decoration: underline;
}
.tox-toolbar .tox-group .line-through-text span {
  text-decoration: line-through;
}

.tox-toolbar .dropdown-field {
  margin: 0;
  padding: 0;
}
.tox-toolbar .dropdown-field .ant-dropdown-trigger {
  font-weight: 700;
  color: #483ad0;
}

.add-sgj-box {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  padding: 10px;
  background: #fff;
  width: 275px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  z-index: 99;
}
.add-sgj-box .input-box {
  margin: 0 0 10px 0;
  padding: 0;
  position: relative;
  z-index: 9;
}
.add-sgj-box .input-box:last-child {
  margin-bottom: 0;
}
.add-sgj-box .input-box .ant-select {
  width: 100%;
  text-align: left;
}

.add-sgj-box .input-box .emoji-btn {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
}
.add-sgj-box .input-box .emoji-btn:after {
  display: none;
}

.add-sgj-box .close-box {
  position: absolute;
  right: -12px;
  top: -12px;
  z-index: 9;
}
.add-sgj-box .close-box .close-btn {
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  outline: none;
  width: 25px;
  height: 25px;
  border: none;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  font-size: 11px;
  color: #000;
  line-height: 0;
}
.add-sgj-box .close-box .close-btn:hover {
  background: #fff !important;
}
.add-sgj-box .close-box .close-btn .anticon {
  line-height: 0;
}

.node-add-btn.add-text-btn {
  position: absolute;
  top: -2px;
  bottom: auto;
  left: auto;
  right: -50px;
  margin-top: 0;
}
.node-add-btn .new-add-btn-box {
  width: 35px;
  height: 35px;
  border: 1px solid #483ad0;
  border-radius: 5px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.node-add-btn .new-add-btn-box .ant-btn {
  margin: 0;
}

.feature-card .heading-block {
  padding-top: 0;
  padding-bottom: 0;
}
.feature-card .content {
  display: flex;
  align-items: center;
}
.feature-card .icon-img {
  display: inline-block;
  margin: 0 12px 0 0;
  padding: 0;
}

.feature-card h3 {
  flex: 1;
  font-size: 18px;
  line-height: 1.2;
  color: #000;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.bottom-node-text p {
  position: relative;
  padding-right: 35px;
}
.bottom-node-text .close-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  background: none;
  border: none;
  box-shadow: none;
  outline: none;
  height: auto;
  margin: 0;
  padding: 0;
  color: #ff6b72;
  visibility: hidden;
  opacity: 0;
}
.bottom-node-text .close-btn:hover {
  color: #ff6b72 !important;
}
.bottom-node-text .close-btn:after {
  display: none;
}

.bottom-node-text p:hover .close-btn {
  visibility: visible;
  opacity: 1;
}

/*=============== Modal Css Start ===============*/
.base-modal.upload-modal {
  width: 400px !important;
}
.base-modal.upload-modal .ant-modal-body {
  padding: 20px;
}

.upload-card-block {
  margin: 0;
}
.upload-card-block .upload-box {
  height: 145px;
  background: #c4c4c4;
  border-radius: 4px;
  overflow: hidden;
}

.upload-card-block .button-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-card-block .button-wrapper .label-box {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 100%;
  background: none;
  cursor: pointer;
  color: #333;
  padding: 10px 0;
  font-size: 16px;
}
.upload-card-block .button-wrapper .label-box .icon-img {
  display: block;
  margin: 0 0 12px 0;
  padding: 0;
}

.upload-card-block #upload {
  display: inline-block;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}

.upload-card-block .divider-text {
  display: block;
  font-size: 17px;
  line-height: 1;
  color: #333;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
  padding: 15px;
  text-align: center;
}

#beamerSelector {
  display: none;
}
/*=============== Modal Css End ===============*/

/* ======= Changes ======= */
.onlyBorders {
  position: absolute;
  width: 100%;
  height: 71px;
  bottom: -5px;
  border: 1px solid #b1b1b7;
  z-index: -1;
  border-bottom: none;
}
.condition-yes {
  position: relative;
  left: -50%;
  bottom: 10px;
}

.condition-no {
  position: relative;
  right: -50%;
  bottom: 10px;
}
.card-box .content {
  cursor: text;
}

.card-info .box .text-block ul li.btn-box {
  margin: 0;
  padding: 0;
}
.card-info .box .text-block ul li.btn-box .ant-btn {
  width: 100%;
  border-radius: 0;
  background: #483ad0;
  border: none;
}

.card-info .box .text-block ul li .title-text {
  margin: 0;
  padding: 0;
}

.editable-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}
.editable-box .static-text {
  display: inline-block;
  color: #483ad0;
  margin: 0 7px 0 0;
  padding: 0;
}
/* .editable-box .ant-input{ width:100%; height: 35px; } */
.editable-box .edit-btn {
  border: none;
  box-shadow: none;
  outline: none;
  width: auto;
  height: auto;
  background: none !important;
  font-size: 13px;
  margin: 0;
  padding: 5px 15px;
  position: relative;
}

.title-text .editable-box .static-text {
  color: #203251;
}

.card-info .box .text-block ul li:nth-child(3) {
  background: #483ad0;
}
.card-info .box .text-block ul li input:read-only,
.card-info .box .text-block ul li textarea:read-only {
  background: transparent;
  border: none;
  outline: none;
}
.card-info .box .text-block ul li:nth-child(3) input:read-only {
  background: transparent;
  border: none;
  outline: none;
  color: white;
}

.card-info .box .text-block ul li:nth-child(3) .editable-box .static-text {
  color: #fff;
}
.card-info .box .text-block ul li:nth-child(3) .editable-box .edit-btn {
  color: #fff;
}
.hidden-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgb(0, 0, 0, 0.5);
  z-index: 99;
}
.tag-box .action-btn {
  display: none;
}
.tag-box:hover .action-btn {
  display: block;
}
.card-info .box .text-block ul li {
  padding-right: 0px;
}
.card-info .box {
  width: 250px;
}

.ant-spin-dot-item {
  background-color: white;
}
.btnSubTitle {
  text-align: center;
  font-size: 11px;
  position: absolute;
  bottom: 5px;
  width: 100%;
  color: #45556090;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 15px;
}
.condition-rule > div {
  position: relative;
  margin-top: 8px;
  display: block;
  color: #444;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px -1px rgb(0 0 0 / 20%);
  cursor: pointer;
  padding: 5px;
  text-align: center;
  width: 100%;
  margin-bottom: 4px;
}
.main-content.no-padding {
  padding: 0px !important;
}
.main-content.grey-canvas {
  background-color: #fafafb;
}
.cursor-pointer {
  cursor: pointer;
}
.table-wrapper {
  padding: 16px;
}

/* Persistant menu */
.persistant-menu > div {
  display: flex;
  flex-direction: row;
}
.persistant-menu > div > div {
  flex: 1;
  padding: 5px 10px;
}
.menu-design {
  position: absolute;
  height: auto;
  width: calc(100% - 32px);
  bottom: 66px;
  background: white;
  border-radius: 0;
  padding-top: 20px;
  padding-bottom: 8px;
  border: none !important;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0 0 4px rgb(10 10 10 / 10%);
  z-index: 2;
  display: none !important;
}
.menu-widget {
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  background: black;
  opacity: 0.3;
  top: 109px;
  margin-left: 16px;
  width: calc(100% - 32px);
  bottom: 67px;
  display: none !important;
}
.visible {
  display: block !important;
}
.menu-design > div {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.facebook-button-class {
  border-radius: 5px;
  padding: 8px 12px;
  background: #0084ff;
  border: none;
  font-weight: 600;
  font-size: large;
  color: white;
  box-shadow: none;
  cursor: pointer;
}

.facebook-button-class > .fa-facebook {
  margin-right: 12px;
}
.hook-text-area {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.hook-text-area {
  padding-left: 52px;
}
.prefix-https {
  position: absolute;
  z-index: 1;
  padding-left: 7px;
  padding-top: 8px;
}
.form-list-dataset {
  overflow: auto;
  height: 300px;
}
.hide-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 400px;
  padding-right: 10px;
}

.hide-scrollbar::-webkit-scrollbar {
  background-color: white;
  border-radius: 10px;
  width: 20px;
}

.hide-scrollbar::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 10px;
border: 5px solid white;
}

.hide-scrollbar::-webkit-scrollbar-thumb:vertical {
  height: 20px !important;
width: 20px;
}
/* /////// */



